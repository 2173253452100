/* eslint-disable import/no-named-default */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import PageContainer from '@cleverrealestate/clever-components-v2/dist/components/PageContainer';
import styles from '@cleverrealestate/clever-components-v2/dist/components/PageContainer/PageContainer.scss';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import SEO from '../components/Seo';
import Layout from '../components/layout';
import useGlobalTOCHandler from '../utils/toc.utils';
import renderContent from '../utils/inline-utils-v3';

const LegalTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const {
    seo,
    title,
    slug,
    renderContext,
    published_at_formatted,
  } = pageContext;
  useGlobalTOCHandler();

  const breadcrumbs = [
    {
      link: '/',
      title: 'Home',
    },
    {
      link: `/${slug}/`,
      title,
    },
  ];

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite
      showBlogNav
    >
      <SEO
        pathname={`/${slug}/`}
        title={seo.title}
        description={seo.meta_description}
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <PageContainer
        title={title}
        breadcrumbs={breadcrumbs}
        publishDate={published_at_formatted}
        contentWidth="default"
        bottomCTA="none"
      >
        <article id="article-content" className={styles.mainContent}>
          { renderContent(renderContext.html, renderContext.context) }
        </article>
      </PageContainer>
    </Layout>
  );
};

LegalTemplate.propTypes = {
  pageContext: PropTypes.any,
};

LegalTemplate.defaultProps = {
  pageContext: {},
};

export default LegalTemplate;
